<template lang="pug">
  main.white-bg
    .inside-content
      .container
        article.article
          .article__header
            .article__header-info
              .article__header-content
                h1 Зачем нужны расчеты?

              .article__header-img
                img(src="~images/blog/dream_house.png")

                //-                             0        768        992          1280
                //+img("i/article-img", "jpg", [719,       943,       1231,         1280], "")

          .article__content
            .article__sidebar
              .article__meta
                .article__meta-item
                  span.article__meta-item-icon <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="#6E7288"/><path d="M7 11v1M7 2v1M2 7h1M11 7h1M7 4v3.6L9.4 10" stroke="#6E7288"/></svg>
                  span.article__meta-item-text Время чтения 10 минут

            .article__content-text

              p Думаем, ни для кого не секрет, что подавляющее большинство частных домов строится не только без проекта, но и вообще без расчетов. И как-то построены. И как-то люди в них живут, и даже не жалуются. Ну и зачем тогда что-то считать?
              p Давайте сделаем так-же: начитаемся форумов, насмотримся дизайнерских журналов, наймем опытных строителей, которые подешевле, и будет нам счастье — коттедж мечты, построенный за год, да по стоимости двушки в городе.
              h2 Что не так?

              p Да все не так. Строительным фирмам коттеджи не интересны — головной боли больше, чем от многоквартирного дома, а объемы работ никакие. Они с удовольствием возьмутся за коттеджный поселок из десятков одинаковых домов, либо будут предлагать вам готовый проект, уже не раз обкатанный ими, с известной (как правило минимальной) себестоимостью. Такой, типовой невыразительный домик, совсем небольшой площади, без излишеств. А вам то нужен дом мечты!
              p Дом мечты «под ключ» вам тоже построят, но будет он стоить... ох, сколько он будет стоить. Если вас это не пугает — вам расчеты точно не нужны, их за вас сделают специалисты. А если ваш бюджет «тютелька в тютельку» - придется самому принимать все решения. И становиться хоть немного строителем.  Точнее — прорабом.
              p Вам придется нанимать бригады полупрофессиональных* строителей на разные виды работ, принимать у них выполненные работы, закупать материалы, «разруливать» конфликты... много чего еще. И бесконечно принимать решения: что купить, чем заменить, можно или нельзя, стОит или не стОит...
              p Принимать взвешенные решения вообще непросто, а для начинающего строителя — втройне. И обязательно встанет главный вопрос — где взять денег. Вот тут все строители единодушны: плановые расходы на стройку умножай минимум на два. Планируешь потратить три миллиона — обязательно потратишь шесть. Но это у других, а вы то форумов начитались, знаете ответы на все вопросы, у вас такого не будет точно... Так ведь?
              p
                em * Профессиональным строителем мы называем того, который получил соответствующее образование в учебном заведении (ПТУ, колледж, ВУЗ), сдал экзамены и получил квалификационный разряд. Т.е. имеющим теоретическую подготовку. Полупрофессиональным — того, который имеет опыт, но не знает теории. Среди полупрофессиональных строителей нередко встречаются «самородки», занимающиеся самообразованием, но часто бывают и абсолютно безграмотные люди.

              h2 Ловушка «длинных денег»
              p Усугубляет ситуацию ложное ощущение «полного кармана» в начале стройки. У вас есть очень приличная сумма денег, вот прямо сейчас. Есть яркое ощущение, что их много. А основные затраты где-то там впереди. И в это время десятки тысяч рублей легко тратятся на дизайнера интерьера, на капитальный забор вокруг пустого участка, на модный строительный вагончик, на сомнительные строительные технологии... А потом начинается жесткая  экономия на действительно важных вещах, определяющих долговечность вашего дома и комфорт в нем. «А давайте без грунтовки...», «зачем такие дорогие трубы...»,  «у нас на остановке мужик кабель продает в два раза дешевле...» - вот это вот все.
              p Строите свой первый дом? Готовьтесь стать скупердяем, считающим каждую копейку. Даже если прямо сейчас деньги жгут ваш карман.

              h2 Страна советов
              p Мы очень любим простые решения сложных задач. Поэтому охотно подражаем друг-другу. Сосед ленточный фундамент залил? И мы такой же зальем. Правда у него скальный грунт в полметре от поверхности, а у нас восемь метров суглинка мягкопластичного под будущим домом — но мы то этого не знаем и знать не хотим. Вот еще — платить за изыскания! Знакомо?
              p Еще больше мы доверяем «опытным мастерам». Ну а как — он же «не один дом построил». А может один! А может плохо построил. Мы же это не проверяем — нам так хочется верить в лучшее. Да и опыт его может в нашем случае не подойти — он строил дома совсем не вашей мечты, а там и стены другие, и бюджет иной.
              p Хотите построить дом, базируясь на советах? Заранее готовьте деньги на исправление ошибок, и деньги немалые.

              h2 Цена ошибки
              p Чем ближе к началу стройки — тем выше цена ошибки. Это очевидно. Залили фундамент «на глазок» - и вот в стене трещина. Причем, трещина эта появится не сразу, а тогда, когда и стены уже встанут, и кровля будет на своем месте, и дом зиму простоит, и отделочные работы будут в самом разгаре. И ничего уже не поделаешь — чаще дешевле новый дом построить, чем исправить «кривой» фундамент.
              p Ну ладно, мы же с вами отоплением занимаемся, а не фундаментами — здесь то какие проблемы? Да те же самые проблемы! Вот мы трубы бросили в черновую стяжку, сверху теплые полы намотали, сверху керамогранит по 1500 руб/м
                sup 2&nbsp;
                | (жене понравился), отделку закончили, приборы отопления повесили, живем. Чувствуем — холодно. А пока стройка шла, было тепло. Правда, тогда в спецовках бегали, а теперь в шортах в кресле сидим. Что делать?
              p Давайте радиатор подлиннее купим, а старый выбросим (кто его купит?). Купили. Выбросили. Холодно. Приглашаем специалиста. Он говорит — надо насос мощнее. Покупаем. Меняем. В спальной радиаторы шуметь начинают так, что не уснешь, а вот здесь все равно холодно. Приглашаем другого специалиста. Он говорит, надо трубу по другому подключать — на эту слишком много радиаторов повесили. А труба то в стяжке. А от котельной другую тянуть можно только через холодный чердак, в теплоизоляции и с греющим кабелем. А до чердака она еще по стене детской должна пройти, надо гипсокартон разбирать, вместе с дизайнерскими обоями из Италии (для детей же ничего не жалко). Обои, опять же, переклеивать. А мы такие красивые молдинги поверх обоев клеили, все теперь в мусор.
              p Вот так все друг за друга и цепляется. А могли бы на эти деньги в отпуск съездить. Да и в котельной та труба пойдет сикось-накось, а было так красиво...

              h2 КСС - коэффициент спокойного сна
              p Знаете, чем отличается опытный монтажник от начинающего? Количеством исправленных ошибок.
              p Никому не нравится, когда на него орут. Когда за свой счет приходится что-то менять и ремонтировать. Не нравится в Новогоднюю ночь ехать к черту на кулички, потому что там что-то у кого-то не греет. И чем опытнее монтажник — тем осторожнее. И диаметр труб он предпочитает побольше, и радиатор подлиннее, и теплоизоляцию потолще, и шаг укладки труб поменьше.
              p Мы это называем коэффициентом спокойного сна. У кого-то он 10%, а у кого-то и за 50%. Часто стоимость работ монтажники определяют исходя из стоимости материалов. Т.е. можно говорить, что КСС — это пустое удорожание стоимости системы отопления. А система отопления — штука недешевая.
              p Если мы говорим о доме площадью в 150 м
                sup 2
                | , с качественными приборами отопления и теплыми полами, то стоимость системы отопления с работой будет (очень грубо и без котельной) порядка 200-300 тыс. руб., а то и больше Вот и оцените, сколько денег вам сэкономит качественно сделанный расчет, снижающий КСС до нуля (на самом деле, в самой методике расчета есть свой КСС, но он совсем небольшой, и научно обоснованный).

              h2 Мечты и стандарты
              p Мы строим дом мечты — вы не забыли? Вряд ли дом мечты состоит из помещений со стандартным коэффициентом остекленности, стандартной высоты, с глухой дверью между ними и с выгороженной лестничной клеткой. Нам хочется жить в доме с высокой светлой гостиной, с большими окнами, с открытым выходом на лестницу. А на втором этаже нам хочется утепленную скатную кровлю, с кровельными окнами. Людям вообще нравятся нестандартные вещи.
              p А как подбирают оборудование «опытные монтажники»? У них есть простые критерии, типа «100 Вт/м
                sup 2&nbsp;
                | в обычной комнате, 120 Вт/м
                sup 2&nbsp;
                | в угловой». И еще есть «чуйка», прямо связанная с КСС. Как при помощи критериев и «чуйки» определить, сколько тепла нужно в двухсветной гостиной, выходящей на лестничную клетку?

              ul
                li Сколько тепла нужно в маленьком помещении с высоченной утепленной скатной кровлей?
                li Можно ли обойтись в данном помещении только теплыми полами, без радиаторов?
                li Хватит ли радиатора, если его на контур теплых полов посадить?

              p На нестандартные задачи нет стандартных решений. И приходится монтажнику рисковать, полагаться только на «чуйку». Соответственно, растет КСС. И растут ваши затраты. А ведь есть точные ответы на все эти вопросы, и их дают только расчеты.

              h2 Всем нравятся модели...
              p Мы сейчас не про те модели, что с длинными ногами. Мы про моделирование. Это когда вы сидите за компьютером и получаете точный ответ на вопросы типа «а что будет, если...».

              ul
                li
                  em А что, если я газобетонные блоки заменю на керамические? Какая должна быть толщина стены, чтобы теплопотери были теми же?
                li
                  em А радиаторы бренда А на бренд Б можно один в один заменить?
                li
                  em А если у меня в кладовой +16 — сколько тепла отдаст радиатор? А на веранде +5 — там сколько?
                li
                  em А если я теплые полы снизу утеплять не буду — сколько я буду переплачивать?
                li
                  em А хватит мне одного теплого пола для отопления? А если не керамогранит, а ламинат? А если c ламинатом хватает, то какая будет его температура (продавец говорит, что выше 27 нельзя)?
                li
                  em А какой мощности мне нужен котел?
                li
                  em А если я повешу резервный котел мощностью 9 кВт (у меня всего 10 кВт разрешенной мощности) — хватит этого, чтобы в доме было +5? А сколько будет, если не хватает?

              p Таких вопросов в вашей голове родится еще не один десяток. И ответы на них вы получите при помощи наших расчетов. Подходите к процессу конструирования вашего дома творчески, экспериментируйте. Это ведь дом мечты. Чем больше таких вопросов вы себе зададите, и чем больше ответов на них дадут наши расчеты — тем оптимальнее будет ваш дом. И не придется потом ничего переделывать.

              h2 Энергоэффективность
              p Это особенный вопрос, который вы обязательно должны себе задать.
              p Мы все хотим жить в экономичном доме, особенно «потом, на пенсии...». И денег лишних на этапе строительства у нас нет. Так бы сделал стены из газобетона толщиной в метр — и все дела, можно вообще без отопления обойтись, получится пассивный дом. Но уж больно дорого получится.
              p Где вот эта волшебная точка, в которой и строить не особо дорого, и в дальнейшем можно жить экономично  и комфортно? Есть такая точка.
              p И дают нам ее нормы по энергоэффективности. Они, кстати, в каждом регионе свои, так и называются «Территориальные строительные нормы по энергетической эффективности». В них приводится методика определения оптимального значения коэффициентов термического сопротивления стен, кровли, пола и прочих ограждающих конструкций. Мы эту методику внедрили в свои расчеты. И теперь вы можете, описав послойно свою ограждающую конструкцию, увидеть, соответствует ли она современным нормам. А если не соответствует, то насколько. Видите, что  перестарались с утеплением — можете уменьшить, эти избыточные инвестиции скорее всего не окупятся.

              blockquote А вот если утепление недостаточно — не жалейте денег, утепляйте. Эти деньги окупятся точно.

              h2 Всемогущая автоматика
              p В подсознании многих людей есть дурацкая уверенность, что автоматика может все.
              p Почему дурацкая? Потому что моги она все — самолеты бы не падали. И Чернобыльской аварии никогда бы не было. Нет, автоматика не всемогуща. Даже в таком простом деле, как отопление.
              p Представим ситуацию — вы не стали заморачиваться с расчетами, и в каждой комнате повесили радиаторы «с запасом». Допустим, выбрали их исходя из ширины окна, все 22 типа, высотой 500. Где-то у вас запас получился небольшой, процентов 10. А где то и все 100. Ну а чего — можете себе позволить. Чтобы не было жарко — на каждый радиатор поставили термоголовку. Везде тепло, все регулируется — класс. Почему бы теперь не поэкономить? Тем более что котел вы себе купили не абы какой, а с современной автоматикой. Она и температуру по графику держит, и снижать ее можно ночами, или когда вас дома нет.
              p Вы инструкцию прочитали, все запрограммировали, ждете снижения расхода газа. А снижения то и незаметно. Начинаете экспериментировать, и видите, что котел температуру исправно снижает, а вот температура в комнатах почти не снижается. Точнее, где то снижается, а где-то вообще нет. В чем дело? А в том самом запасе и дело.
              p Термоголовка у вас настроена на комфортную температуру, и отключает эту избыточную мощность только при температуре выше комфортной. Умный котел снизил температуру, а радиатору и этой температуры достаточно, чтобы температура была комфортной.
              p Если бы все радиаторы были с одинаковым коэффициентом запаса — можно было бы выбрать другую отопительную кривую. Но в реальной жизни запас везде окажется разным, и экономить толком не получится.
              p Можно, конечно, наворачивать автоматику и дальше -  в каждую комнату поставить комнатный термостат с недельным таймером, сервоприводы вместо термоголовок (да все это на радиосвязи, раз сразу кабели не предусмотрели). Не лучше ли потратить день и посчитать теплопотери и радиаторы? А на сэкономленные деньги - в отпуск к морю?

              h2 #Трудовыебудни

              p Знаете, какие слова вы будете ненавидеть после окончания строительства? Да даже раньше. Это слова «наличие» и «срок поставки».
              p Того, что вы так тщательно выбирали, нет в наличии. Срок поставки рушит все планы. Что-то есть вот там, но там у вас скидок нет. Еще там и там есть, но это Газель гонять по всему городу. А это вроде и заказали давно, и оплатили вовремя — а привезли не то. И опять срок поставки. А то, что есть — непонятно, подходит или не подходит. А тут еще менеджер советует — и в наличии, и дешевле, и даже лучше вроде — вот верить или нет? А тут не входит, надо ниже. Значит, будет толще. А насколько? Вот в этом колесе вы и будете кружится каждый день, как заправская белка. Не переживайте, мы и в этом вопросе поможем.
              p В наших расчетах большая база данных материалов и оборудования для систем отопления. Все, что туда попадает, проходит нашу проверку на соответствие заявленным характеристикам. И вы в любой момент «в один клик» можете подобрать аналог. Кучу времени сэкономите. И нервы будут сохраннее.

              h2 Я почему раньше вредный был? Потому что у меня велосипеда не было.
              p Почему все так привыкли обходится без расчетов? Потому что это было сложно. Надо было рыться в справочниках. Считать по формулам и составлять таблицы. Причем расчеты эти были не в одно действие, и легко было ошибиться.
              p Сегодняшние технологии дали нам возможность сделать инженерные расчеты элементарными. Мы и сделали. Отведите день на просмотр обучающих роликов. Еще день на «попробовать» и пообщаться с нашим онлайн-консультантом. И на третий день можете приступать к расчету системы отопления вашего дома мечты. Разве это не здорово?

              .article__register
                a.button.button_green(@click="goSignUp") Регистрация

              //.article__likes
                .article__likes-title Этот материал был вам полезен?
                .article__likes-buttons
                  button.article__likes-button(type="button")
                    span.article__likes-button-text Да
                    span.article__likes-button-icon <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity=".2" d="M3 9.75h4.5v9.75H3a.75.75 0 01-.75-.75V10.5A.75.75 0 013 9.75z" fill="#696E8A"/><path d="M21.74 7.51a2.25 2.25 0 00-1.69-.76H15v-1.5a3.75 3.75 0 00-3.75-3.75.75.75 0 00-.67.41L7.04 9H3a1.5 1.5 0 00-1.5 1.5v8.25a1.5 1.5 0 001.5 1.5h15.93a2.25 2.25 0 002.23-1.97l1.12-9a2.25 2.25 0 00-.54-1.77zM3 10.5h3.75v8.25H3V10.5zm17.8-1.4l-1.13 9a.75.75 0 01-.74.65H8.25V9.93l3.44-6.89a2.25 2.25 0 011.8 2.21V7.5a.75.75 0 00.76.75h5.8a.75.75 0 01.74.84z" fill="#696E8A"/></svg>
                  button.article__likes-button(type="button")
                    span.article__likes-button-text Нет
                    span.article__likes-button-icon <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity=".2" d="M3 14.25h4.5V4.5H3a.75.75 0 00-.75.75v8.25a.75.75 0 00.75.75z" fill="#696E8A"/><path d="M21.74 16.49a2.25 2.25 0 01-1.69.76H15v1.5a3.75 3.75 0 01-3.75 3.75.75.75 0 01-.67-.41L7.04 15H3a1.5 1.5 0 01-1.5-1.5V5.25A1.5 1.5 0 013 3.75h15.93a2.25 2.25 0 012.23 1.97l1.12 9a2.25 2.25 0 01-.54 1.77zM3 13.5h3.75V5.25H3v8.25zm17.8 1.4l-1.13-9a.75.75 0 00-.74-.65H8.25v8.82l3.44 6.89a2.25 2.25 0 001.8-2.21V16.5a.75.75 0 01.76-.75h5.8a.75.75 0 00.74-.84z" fill="#696E8A"/></svg>

        section.related
          .related__title Другие статьи

          .prev-next
            a.prev-next__item(@click="goHeatloss") Предыдущая
            a.prev-next__item(@click="goRadiator") Следующая

          flickity.related__grid(ref="flickity" :options="flickityOptions")

            article.article-card
              a.article-card__img(@click="goHeatloss")
                img(src="~images/blog/house.svg" style="width: 60%")
                //-                             0        768        992          1280
                //+img("i/article-img-5", "jpg", [407,     407,       407,         407], "")

              .blog-card__content
                a.blog-card__title(@click="goHeatloss") Расчет теплопотерь
                .blog-desc
                  p Наш онлайн калькулятор теплопотерь позволяет сделать расчет в двух вариантах: Упрощенный или Подробный.
                .article__meta
                  time.article__meta-item
                    span.article__meta-item-text 02.01.2021
                  .article__meta-item
                    span.article__meta-item-icon <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="#6E7288"/><path d="M7 11v1M7 2v1M2 7h1M11 7h1M7 4v3.6L9.4 10" stroke="#6E7288"/></svg>
                    span.article__meta-item-text 3 минуты

                  a.blog-card__read-more(@click="goHeatloss")
                    span.blog-card__read-more-text Читать  дальше
                    span.blog-card__read-more-arrow <svg viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.35 4.35a.5.5 0 000-.7L21.17.46a.5.5 0 10-.7.71L23.28 4l-2.83 2.83a.5.5 0 10.71.7l3.18-3.18zM0 4.5h24v-1H0v1z" fill="#505571"/></svg>

            article.article-card
              a.article-card__img(@click="goRadiator")
                img(src="~images/blog/radiator.svg" style="width: 50%")
                //-                             0        768        992          1280
                //+img("i/article-img-5", "jpg", [407,     407,       407,         407], "")

              .blog-card__content
                a.blog-card__title(@click="goRadiator") Расчет радиаторов
                .blog-desc
                  p В советское время выбор радиатора (прибора отопления) был предопределен. Вот вам чугунная батарея – грейтесь.

                .article__meta
                  time.article__meta-item
                    span.article__meta-item-text 02.01.2021
                  .article__meta-item
                    span.article__meta-item-icon <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="#6E7288"/><path d="M7 11v1M7 2v1M2 7h1M11 7h1M7 4v3.6L9.4 10" stroke="#6E7288"/></svg>
                    span.article__meta-item-text 4 минуты

                  a.blog-card__read-more(@click="goRadiator")
                    span.blog-card__read-more-text Читать  дальше
                    span.blog-card__read-more-arrow <svg viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.35 4.35a.5.5 0 000-.7L21.17.46a.5.5 0 10-.7.71L23.28 4l-2.83 2.83a.5.5 0 10.71.7l3.18-3.18zM0 4.5h24v-1H0v1z" fill="#505571"/></svg>

            article.article-card
              a.article-card__img(@click="goFloor")
                img(src="~images/blog/warm-floor.svg" style="width: 50%")
                //-                             0        768        992          1280
                //+img("i/article-img-5", "jpg", [407,     407,       407,         407], "")

              .blog-card__content
                a.blog-card__title(@click="goFloor") Расчет теплого пола
                .blog-desc
                  p Водяной теплый пол — это залитые в бетон трубы. Если что-то сделано неправильно, устранение ошибки обойдется очень дорого.

                .article__meta
                  time.article__meta-item
                    span.article__meta-item-text 02.01.2021
                  .article__meta-item
                    span.article__meta-item-icon <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="#6E7288"/><path d="M7 11v1M7 2v1M2 7h1M11 7h1M7 4v3.6L9.4 10" stroke="#6E7288"/></svg>
                    span.article__meta-item-text 5 минут

                  a.blog-card__read-more(@click="goFloor")
                    span.blog-card__read-more-text Читать  дальше
                    span.blog-card__read-more-arrow <svg viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.35 4.35a.5.5 0 000-.7L21.17.46a.5.5 0 10-.7.71L23.28 4l-2.83 2.83a.5.5 0 10.71.7l3.18-3.18zM0 4.5h24v-1H0v1z" fill="#505571"/></svg>
</template>
<script>
import Flickity from 'vue-flickity'
import navigationMixin from 'mixins/navigation'

export default {
  name: 'ZachemNuzhnyRaschety',
  mixins: [navigationMixin],
  data () {
    return {
      flickityOptions: {
        groupCells: true,
        wrapAround: false,
        contain: true,
        autoPlay: false,
        adaptiveHeight: false,
        prevNextButtons: false,
        pageDots: false,
        resize: false
      }
    }
  },
  components: {
    Flickity
  },
  metaInfo: {
    title: 'Будущему владельцу дома - зачем нужны расчеты?',
    meta: [
      {
        name: 'description',
        content: 'Узнайте, для чего использовать калькулятор отопления для вашего дома!'
      }
    ]
  }
}
</script>
